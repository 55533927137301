<template>
  <div class="news-open-page page">
    <div class="news-open-page__header">
      <div class="container-padding">
        <div class="news-open-page__inner">
          <PageBackComponent />
          <div class="news-open-page__content">
            <h2>{{ news_item.title }}</h2>
            <NewsInfoComponent :news="news_item" item />
          </div>
        </div>
      </div>
    </div>
    <div class="news-open-page__bottom">
      <div class="container-padding">
        <div class="news-open-page__content">
          <NewsImagesComponent :images="news_item.images" />
          <NewsShareComponent :date="news_item.published_at" />
          <NewsOtherComponent :news="news" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageBackComponent from "components/PageBackComponent.vue";
import NewsInfoComponent from "../components/NewsInfoComponent.vue";
import NewsImagesComponent from "../components/NewsImagesComponent.vue";
import NewsOtherComponent from "../components/NewsOtherComponent.vue";
import NEWS_OPEN_PAGE from "@/graphql/pages/news_open_page.graphql";
import NewsShareComponent from "../components/NewsShareComponent.vue";

export default {
  name: "NewsOpenPage",
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: NEWS_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
          first: parseInt(4),
        },
      })
      .then(({ data }) => {
        store.state.news_open_page.news = data.news_paginate;
        store.state.news_open_page.news_item = data.news_item;
      });
  },
  metaInfo() {
    const meta = this.$store.state.news_open_page.news_item;
    return {
      title: meta?.title,
    };
  },
  computed: {
    news() {
      return this.$store.state.news_open_page.news.data;
    },
    news_item() {
      return this.$store.state.news_open_page.news_item;
    },
  },
  components: {
    NewsShareComponent,
    NewsOtherComponent,
    NewsImagesComponent,
    NewsInfoComponent,
    PageBackComponent,
  },
};
</script>

<style lang="stylus">
.news-open-page {

  &__header {
    padding 126px 0 50px 0
    background var(--gray-light)
  }

  &__bottom {
    padding 50px 0 0 0
  }

  &__inner {
    display flex
    flex-direction column
    gap 30px
  }

  &__content {
    display flex
    flex-direction column
    gap 50px
  }
}
</style>
