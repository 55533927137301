<template>
  <div class="news-info">
    <ImgComponent :img="news.img" class="news-info__image" />
    <div class="news-info__content">
      <span v-if="news.published_at && !item" class="news-info__date">
        {{ news.published_at | formatDate }}
      </span>
      <h3 v-if="news.title && !item" class="news-info__title">{{ news.title }}</h3>
      <EditorJSComponent v-if="news.description" :text="JSON.parse(news.description)" />
      <router-link v-if="!item" :to="{ name: 'news-item', params: { id: news.id } }" class="news-info__icon">
        <IconComponent name="arrow-right-line" />
      </router-link>
    </div>
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
import IconComponent from "components/IconComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "NewsInfoComponent",
  props: {
    news: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Boolean,
      default: false,
    },
  },
  components: { EditorJSComponent, IconComponent, ImgComponent },
};
</script>

<style lang="stylus">
.news-info {
  display flex
  gap 50px
  +below(1300px) {
    gap 30px
  }
  +below(1200px) {
    flex-direction column
  }

  &__content {
    max-width 601px
    display flex
    flex-direction column
  }

  &__image {
    max-width 601px
    max-height 430px
    border-radius var(--main-radius)
  }

  &__date {
    font-size .875em
    line-height 22px
    margin-bottom 10px
  }

  &__title {
    margin-bottom 15px
  }

  &__icon {
    margin-top 15px
    max-width 50px
    max-height 7px
  }
}
</style>
