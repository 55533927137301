<template>
  <div class="news-other">
    <h2>Читайте также</h2>
    <div class="news-other__list">
      <NewsCardComponent v-for="(news, i) in news" :key="i" :news="news" />
    </div>
  </div>
</template>

<script>
import NewsCardComponent from "components/NewsCardComponent.vue";

export default {
  name: "NewsOtherComponent",
  props: {
    news: {
      type: Array,
      default: () => [],
    },
  },
  components: { NewsCardComponent },
};
</script>

<style lang="stylus">
.news-other {
  display flex
  flex-direction column
  gap 50px

  &__list {
    display flex
    flex-wrap wrap
    gap 50px
    //+below(550px) {
    //  flex-direction column
    //}
  }
}
</style>
