<template>
  <div class="news-images">
    <ImgComponent v-for="(image, i) in images" :key="i" :img="image.img" class="news-images__image" />
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "NewsImagesComponent",
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  components: { ImgComponent },
};
</script>

<style lang="stylus">
.news-images {
  display grid
  grid-template-columns repeat(4, 1fr)
  grid-gap 50px
  +below(1200px) {
    grid-gap 20px
  }
  +below(1000px) {
    grid-template-columns repeat(3, 1fr)
  }
  +below(750px) {
    grid-template-columns repeat(2, 1fr)
  }
  +below(500px) {
    grid-template-columns 1fr
  }

  &__image {
    width 100%
    max-width 275px
    height 210px
    max-height 210px
    border-radius var(--main-radius)
    +below(1000px) {
      max-width 100%
    }
  }
}
</style>
