<template>
  <div class="news-share">
    <PageBackComponent class="news-share__back" />
    <div class="news-share__right">
      <div class="news-share__icons">
        <ShareNetworksComponent />
      </div>
      <span class="news-share__date">{{ date | formatDate }}</span>
    </div>
  </div>
</template>

<script>
import PageBackComponent from "components/PageBackComponent.vue";
import ShareNetworksComponent from "components/ShareNetworksComponent.vue";

export default {
  name: "NewsShareComponent",
  props: {
    date: {
      type: String,
      default: "",
    },
  },
  components: { ShareNetworksComponent, PageBackComponent },
};
</script>

<style lang="stylus">
.news-share {
  display flex
  align-items center
  justify-content space-between
  background var(--gray-light)
  padding 10px 15px
  border-radius var(--small-radius)
  +below(620px) {
    justify-content center
  }
  +below(405px) {
    padding 10px
  }

  .ya-share2__badge {
    background-color transparent !important
  }

  &__back {
    +below(620px) {
      display none
    }
  }

  &__right {
    display flex
    align-items center
    gap 40px
    +below(405px) {
      gap 15px
    }
    +below(370px) {
      flex-direction column
    }

    span {
      font-size .875em
      line-height 22px
      color var(--gray)
    }
  }

  &__icons {
    display flex
    align-items center
    gap 5px
  }
}
</style>
