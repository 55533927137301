<template>
  <div class="share-networks" :class="{ 'share-networks--column': column }">
    <span class="share-networks__title" v-if="!column">Поделиться:</span>
    <ul class="share-networks__list">
      <li v-for="(item, i) in socials" :key="i">
        <ShareNetwork :url="currentUrl" :network="item.network" title="">
          <span class="share-networks__item">
            <span :title="item.title">
              <IconComponent :name="item.icon" />
            </span>
            <span class="share-networks__item-title" v-show="column">{{ item.title }}</span>
          </span>
        </ShareNetwork>
      </li>
      <li>
        <a v-if="column" @click.prevent="copyUrl" href="#">
          <span class="share-networks__item">
            <span title="Скопировать ссылку">
              <IconComponent name="copy" />
            </span>
            <span class="share-networks__item-title" v-show="column">Скопировать ссылку</span>
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "ShareNetworksComponent",
  components: { IconComponent },
  props: {
    column: Boolean,
  },
  data() {
    return {
      socials: [
        {
          title: "ВКонтакте",
          network: "vk",
          icon: "vk",
        },
        {
          title: "Одноклассники",
          network: "odnoklassniki",
          icon: "ok",
        },
        {
          title: "WhatsApp",
          network: "whatsapp",
          icon: "whatsapp",
        },
        {
          title: "Telegram",
          network: "telegram",
          icon: "telegram",
        },
      ],
    };
  },
  computed: {
    currentUrl() {
      return this.$store.state._env.MEDIA_ENDPOINT + this.$route.fullPath;
    },
  },
  methods: {
    async copyUrl() {
      try {
        await navigator.clipboard.writeText(location.href);
      } catch (err) {
        let inp = document.createElement("input");
        inp.value = location.href;
        document.body.appendChild(inp);
        inp.select();
        document.execCommand("copy");
        document.body.removeChild(inp);
      }
      // this.done = true;
      // setTimeout(() => {
      //   this.done = false;
      // }, 1000);
    },
  },
};
</script>

<style lang="stylus">
.share-networks {
  display flex
  align-items center
  justify-content center
  margin 0 auto
  gap: 5px

  &__title {
    display flex
    font-size .875em
    line-height 22px
    color var(--gray)
  }

  &--column &__list {
    display flex
    flex-direction column
  }

  &__list {
    display flex
    flex-direction row
    gap: 5px

    li {
      display flex
      align-items center

      a {
        width 100%
      }
    }
  }

  &__item {
    display flex
    align-items center
    gap 5px

    > span {
      display flex
      white-space nowrap
      flex-shrink 0
      color var(--black)
      font-size .875em
      line-height 22px
    }

    .icon {
      width: 100%
      max-width 22px
      height: 100%
      max-height 22px
    }

    .icon {
      svg path {
        fill var(--black)
        transition var(--transition)
      }
    }

  }
}
</style>
